import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/404'
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
    meta: {
      title: '测试'
    },
  },
  {
    path: '/indexorder19',
    name: 'indexorder19',
    component: () => import('../views/indexorder19.vue'),
    meta: {
      title: '慧推广权益升级版'
    },
  },
  {
    path: '/indexorder9',
    name: 'indexorder9',
    component: () => import('../views/indexorder9.vue'),
    meta: {
      title: '慧推广权益标准版'
    },
  },
 
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router