import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  tabbar: 1,
  source: "APP",
  tabbarActive: 0,
  cateringList: {},
  token: "",
  userInfo: {
    height: 170,
    weight: 60,
    intensity: 2,
    RNI: "",
    BMI: "",
    Reminds: "",
    TiXing: ""
  }
}

const actions = {
  showTabbar ({ commit }) {
    commit('ShowTabbar')
  },
  hideTabbar ({ commit }) {
    commit('HideTabbar')
  },
  setCateringList ({ commit }, CateringList) {
    commit('SetCateringList', CateringList)
  },
  setToken ({ commit }, Token) {
    commit('SetToken', Token)
  },
  setTabbar ({ commit }, TabbarActive) {
    commit('SetTabbar', TabbarActive)
  },
  setUserInfo ({ commit }, UserInfo) {
    commit('SetUserInfo', UserInfo)
  },
  setSource ({ commit }, Source) {
    commit('SetSource', Source)
  },
}

const mutations = {
  ShowTabbar () {
    this.state.tabbar = 1;
  },
  HideTabbar () {
    this.state.tabbar = 0;
  },
  SetCateringList (state, CateringList) {
    state.cateringList = CateringList;
  },
  SetToken (state, Token) {
    state.token = Token;
    sessionStorage.setItem("Token", state.token)
  },
  SetTabbar (state, TabbarActive) {
    state.tabbarActive = TabbarActive;
  },
  SetUserInfo (state, UserInfo) {
    state.userInfo = UserInfo;
    localStorage.setItem("UserInfo", JSON.stringify(state.userInfo))
  },
  SetSource (state, Source) {
    state.source = Source;
  },
}

const getters = {
  token () {
    let token = sessionStorage.getItem("Token");
    if (token) {
      return token
    }
    else {
      return state.token;
    }

  },
  userInfo () {
    let userInfo = localStorage.getItem("UserInfo");
    if (userInfo) {
      return JSON.parse(userInfo)
    }
    else {
      return state.userInfo;
    }
  },
  source () {
    return state.source;
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
