import axios from 'axios'
//import Router from '@/router'
import store from '@/store'

axios.defaults.baseURL = '/api/user'

var loadinginstace

axios.interceptors.request.use((config) => {    // 这里的config包含每次请求的内容

  let token = store.getters['token']  
  // let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRUeXBlIjoiUWlZZVdlaVhpbiIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzM3NzA1NjU2LCJ1c2VyaWQiOiJQb0h1YVNoaUppbiJ9.S8_1RZDZ-FluAGsdwutns1JEyQsnGAO7xJXietl3mjA'
  // console.info(token)
  config.headers.Authorization = 'Bearer ' + `${token}`;
  //console.info(config.headers.Authorization)
  //if (config.method == 'post') {
  // loadinginstace = ElLoading.service({
  //     lock: true,
  //     text: '提交中，请稍候',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });
  //}
  return config;
}, function (err) {
  if (loadinginstace) loadinginstace.close()
  return Promise.reject(err);
})

// //拦截器
axios.interceptors.response.use(
  response => {
    console.log("error")
    if (loadinginstace) loadinginstace.close()
    // if (response.data.token) {
    //     window.sessionStorage.setItem('token', response.data.token)
    // }
    return response
  },
  error => {
    // console.log(error)
    if (loadinginstace) loadinginstace.close()
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          confirm('登录已过期')
          break;
        //   router.replace({
        //     path: '/login'
        //   })
        //   location.reload()
        default:
          //Router.push('/login')
          confirm('登录已过期：' + error.response.status + "错误")
      }
    }
    return Promise.reject(error)   // 返回接口返回的错误信息
  })


export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/api/"
    axios.get(url,{
      params: params
    })
      .then(response => {
        console.log(response.data);
        resolve(response.data);
      })
      .catch(err => {        
        reject(err)
      })
  })
}
export function infoget (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/api/"
    axios.get(url,{
      params: params
    })
      .then(response => {
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(err => {        
        reject(err)
      })
  })
}
export function infopost (url, data = {}) {
  // console.info(axios.defaults.baseURL)
  // console.info(data)
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/api/"
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function wxget (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/weixin"
    axios.get(url,{
      params: params
    })
      .then(response => {
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(err => {  
        console.log("err");      
        reject(err)
      })
  })
}
export function post (url, data = {}) {
  // console.info(axios.defaults.baseURL)
  // console.info(data)
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/api/"
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function imgpost (url, data = {}) {
  // console.info(url)
  
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/api/img"
        const formData = new FormData();
        formData.append('FileData',data); // 假设你的文件在 data 对象的 file 属性中
        console.info(data)
        console.log("------------------分割线-------------------");
        console.log(formData.get("FileData")); // 打印返回结果
        axios({
        method: 'post',
        url: url, // 设置上传接口地址
        data: formData,
        })
        .then((response) => {
          resolve(response.data);
        //   console.log("response.data");
        
        // console.log("------------------分割线1-------------------");
        // console.log(response); // 打印返回结果
        })
        .catch((error) => {
          reject(error)
        // console.error("error");
        // console.error(error.data);
        });
  })
}

export function wxpost (url, data = {}) {
  // console.info(url)
  
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/weixin"
      axios.post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err)
        })
    })
}

export function wxpost2 (url, data = {}) {
  // console.info(axios.defaults.baseURL)
  // console.info(data)
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/weixin"
    axios.post(url)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function smspost (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.defaults.baseURL = "/sms"
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}