<template>
  <div class="bg">
    <div class="fuwuimg">
      <van-image  :src="require('@/assets/images/indexorder19.png')">
      </van-image>

      <van-form class="kaitongform" >
        <van-cell-group>
          <van-field v-model="phone"
                   placeholder="请输入电信手机号码" />
          <van-field v-model="code"
                   center
                   clearable
                   placeholder="请输入验证码">
            <template #button>
              <van-button size="small"
                        plain
                        class="sendcode"
                        @click="getcode">{{ sendBtnText }}</van-button>
            </template>
          </van-field>
        </van-cell-group>
        <van-button class="kaitong"
                  block @click="queren">立即开通 享超值特权</van-button>
          
      </van-form>
      <van-cell class="shuoming">
            <span style="font-size: 14px;line-height:24px ;">慧推广权益升级版12期合约资费：￥19/月 </span>
            <van-checkbox v-model="checked" shape="square" class="check-box" >
            <span style="font-size: 12px;line-height:24px ;">
            我已仔细阅读<a @click="popyewu"
               style="color: red;">《业务说明》</a>及<a @click="popyinsi"
               style="color: red;">《隐私协议》</a></span></van-checkbox>
          </van-cell>
      <!-- <van-cell class="shuoming" >
        <span>慧推广权益升级版12期合约资费：￥9/月 </span>
        <template>
          
        </template>
          
      </van-cell> -->
    </div> 
    <van-popup v-model="yewushow"
               closeable
               position="bottom"
               :style="{ height: '90%' }">
      <div style="padding:20px;">
        <div style="height: 20px"></div>
        <div>
          <h3>业务说明</h3>
          <p>
            （一）、业务名称：慧推广权益升级版12期合约<br />
            （二）、资费标准：<a style="color: red;">资费￥19元/月</a><br />
            （三）、服务内容：<br />
            （1）、会员权益：<a style="color: red;">会员权益每月任选2款，会员权益包括影音会员、美食权益、购物权益、出行权益等四大类。</a><br />
            （2）、权益每月自动更新，具体权益内容和领取规则以领取页面为准。<br />
            （3）、权益领取当月有效，过期不补。<br />
            （四）、业务使用规则<br />
            （1）、计费规则：<a style="color: red;">￥19元/月，订购当月与退订当月全额扣费。</a><br />
            （2）、生效规则：新老用户均可订购，订购立即生效，<a style="color: red;">标准资费￥19元/月。</a><br />
            （3）、变更、退订规则：次月生效。<br />
            （4）、合约期：合约期12个月，到期后原产品资费和内容保持不变。<br />
            （五）、权益领取<br />
             &nbsp;&nbsp;&nbsp;&nbsp;领取说明：<a style="color: red;">关注“湖北电信”微信公众号，回复关键词“慧推广”领取，具体权益内容和领取规则以领取页面为准。</a><br />
            （六）、咨询热线：如有任何疑问，请拨打<a style="color: red;">4008018114。</a>
          </p>
        <van-button type="primary"
                      color="#fd7ea3"
                      @click="yewuread"
                      block>我已阅读并同意</van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="yinsishow"
               closeable
               position="bottom"
               :style="{ height: '90%' }">
      <div style="padding:20px;">
        <div style="height: 20px"></div>
        <div>
            <h3>个人信息授权与保护声明</h3>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;尊敬的用户，本公司(以下简称“我们”)将按照本声明及《隐私权保护政策》的规定收集、保存、使用、储存、分享、披露及保护您的个人信息。请您务必仔细阅读并了解《隐私协议》 (以下简称为“本协议”)。对于重要条款我们采用粗体及下划线方式进行显著标注以提示您注意。您一旦主动在页面填写您的个人信息并进行提交操作，即意味着您同意我们按照本协议收集、使用、共享您的相关信息。若您不接受本协议，请勿登记您的个人信息。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;我们的页面和服务主要面向成人。我们不会主动收集未成年人的个人信息，如果您未满18周岁，请您在监护人陪同下仔细阅读并充分理解本协议，并征得监护人的同意后使用我们的服务或向我们提供信息。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;本协议将帮助您了解以下内容：<br />
            一、收集信息的原则及目的<br />
            二、如何收集和使用您的个人信息<br />
            三、如何共享、转让您的个人信息<br />
            四、我们如何使用 Cookie<br />
            五、如何保存与保护您的个人信息<br />
            六、您对个人信息享有的权利<br />
            七、政策更新<br />
            八、联系我们
          </p>
          
          <p>一、收集信息的原则及目的</p>
          <p>1.保障为您提供的产品或服务功能的正常实现。</p>
          <p>2.实现对您的推送功能。</p>
          <p>3.帮助我们维护和改进我们的产品或服务，提升用户体验。</p>
          <p>二、如何收集和使用您的个人信息</p>
          <p>我们将合法合规对您的个人信息进行采集、使用。</p>
          <p>您已知晓并同意在您下次浏览本落地页面时，我们会帮您预先填写您上次输入的历史信息以提升您的使用体验。您亦可拒绝使用预填充功能。</p>
          <p>(一) 收集和使用您的个人信息的范围
          <p>1.下单及订单管理</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;为了向您提供相关服务，当您准备登记您的信息以促成交易的达成时，我们将相应收集您的手机号。同时，所涉订单中会载明订单编号、您所购买的商品或服务信息、下单时间等。具体需要填写的信息可能会根据我们提供的产品/服务的不同而有所差异，请以届时向您展示的产品/服务以及所对应的要求填写相关个人信息。
          </p>
          <p>2.维护基本功能的正常运行、网络及运营安全</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;在您使用我们的服务过程中，为向您提供浏览等基本功能并识别是否异常状态、了解产品适配性、保障产品服务的网络及运营安全，以维护前述基本功能的正常运行，我们可能会收集、存储关于您使用的服务以及使用方式的日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，并将这些信息进行关联来综合判断您帐号及交易风险进行身份验证、检测以及防范安全事件，并依法采取必要的记录、审计、分析、处置措施：</p>
          <p>(1) 日志信息: 当您使用我们服务时，我们会收集您的浏览日志、操作信息、互动记录、IP地址、浏览器UA信息、浏览器的类型、屏幕分辨率、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、停留时长。</p>
          <p>(二)您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：</p>
          <p>(1) 与国家安全、国防安全直接相关的；</p>
          <p>(2) 与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>(3) 与犯罪侦查、起诉、审判和判决执行直接相关的；</p>
          <p>(4) 出于维护个人信息主体或其他个人的生命、财产重大合法权益但又很难得到本人同意的；</p>
          <p>(5) 所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
          <p>(6) 从合法的新闻报道、政府信息公开渠道公开披露的信息中收集的您的个人信息的；</p>
          <p>(7) 根据您的要求签订合同所必需的；</p>
          <p>(8) 用于发现、处置产品与/或服务的故障所提供的产品与/或服务的安全稳定运行所必需的；</p>
          <p>(9) 为合法的新闻报道所必需的；</p>
          <p>(10) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
          <p>(11) 法律法规规定的其他情形。</p>
          <p>三、如何共享、转让您的个人信息</p>
          <p>(一) 共享</p>
          <p>仅为实现本协议之目的，我们可能会与关联公司、合作伙伴共享您的某些个人信息。对我们与之共享个人信息的公司组织和个人，我们会与其签署严格的合规协定或做类似要求，以规范其处理个人信息的行为，并促使其具备个人信息的安全保障能力。</p>
          <p>我们将与以下授权合作伙伴共享您的信息：</p>
          <p>1.供应商、服务提供商和其他合作伙伴 (包括相关代理商)。为向您进行货物配送及提供服务，我们可能会将您主动登记提供的信息向该商品及服务的提供商，以及为此目的所涉及的必要第三方进行共享，并由其在您已经向其授权的范围内使用，包括向您发送货物或与您进行必要的联系。</p>
          <p>2.广告、分析服务类的授权合作伙伴。我们可能会将您的匿名化或去标识化的个人信息及您的设备信息与广告、分析服务类的授权合作伙伴共享，以帮助其在不识别您的个人身份的前提下提升广告有效触达率。</p>
          <p>(二) 转让</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;我们不会将您的个人信息转让给任何第三方，但基于您的明确同意或法律法规要求或涉及合并、收购、分立、重组或破产清算的情况除外。</p>
          <p>四、我们如何使用 Cookie 和同类技术</p>
          <p>(一) Cookie</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;为确保咪咕产品正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，我们能够了解您的兴趣偏好及使用习惯。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;我们不会将 Cookie 用于本协议所述目的之外的任何用途您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们产品时亲自更改用户设置。</p>
          <p>(二) Do Not Track (请勿追踪)</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。</p>
          <p>五、如何保存与保护您的个人信息</p>
          <p>我们的信息保护规则：</p>
          <p>1.收集到您的个人信息后，在保障供应商、服务提供商和其他合作伙伴或必要第三方能够凭借相关信息进行货物发送或服务提供的基础上，我们将通过技术手段及时对数据进行匿名化或去标识化处理。</p>
          <p>2.我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露等。</p>
          <p>3.如果我们的管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，我们将及时将事件相关情况及时向您告知，难以逐一告知时，我们会采取公告的方式。同时，我们还将主动上报个人信息安全事件的处置情况。</p>
          <p>我们的信息保存方式：</p>
          <p>1.保存期限: 您提供的个人信息，将在上文所声明的服务提供过程中持续授权我们使用。在您删除个人信息时，我们将视为您撤回了对于该等个人信息收集、使用或共享的授权或同意。</p>
          <p>2.保存地域: 您的个人信息均储存于中华人民共和国境内;如需跨境传输，我们将会单独征得您的授权同意六、您对个人信息享有的权利按照中国相关的法律、法规、标准，我们保障您对自己的个人信息的更正权、删除权或授权撤回等相关权益。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;为实现本第五条所述权利，您可通过本协议第八条“联系我们”中所列明的联系方式与我们进行联系，我们在验明主体身份，确认相关要求和理由后，为您进行相应的更正、删除及授权撤回。</p>
          <p>七、政策更新</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;基于服务优化或情况变更等事由，本协议会不定期进行更新。更新版本将通过在官方网站、广告落地页面等我们正在运营或进行合作的各类相关产品中发出，也请您访问我们网站及软件以便及时了解最新的《个人信息授权与保护声明》。</p>
          <p>八、联系我们</p>
          <p>如有任何疑问，请拨打4008018114。</p>
        <van-button type="primary"
                      color="#fd7ea3"
                      @click="yinsiread"
                      block>我已阅读并同意</van-button>
        </div>
      </div>
    </van-popup>

    <van-dialog id="exchange-confirm" class="shadow" v-model="showDialog" :showConfirmButton="false">
        <div class="queren" >
            <div class="dialog-content">
               	<div class="exchange-main">
	            </div>
	            <div>
	               <van-button class="btn-tuichu" @click="exit()"></van-button>
	               <van-button class="btn-dingou" @click="kaitong()"></van-button>
	            </div>
             </div>
             <div class="tip"></div>
        </div>
    </van-dialog>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data () {

    return {
        thisWidth:0,
        thisHeight:0,
        areaCode:0,
        token:'',
        phone: "",
        code: "",
        isSmsSend: false,
        sendBtnText: "获取验证码",
        // 计时器对象
        timer: null,
        // 倒数60秒
        counter: 60,
        checked: false,
        show: false,
        yewushow: false,
        yinsishow: false,
        showDialog: false,
        // activeIcon: require('../assets/images/xuanzeb.png'),
        // inactiveIcon: require('../assets/images/weixuanzeb.png'),
    };
  },
  methods: {
    popyewu(){
        this.yewushow = true;
    },
    popyinsi(){
        this.yinsishow = true;
    },
    yewuread () {
      this.checked = true;
      this.yewushow = false;
    },
    yinsiread () {
      this.checked = true;
      this.yinsishow = false;
    },
    queren(){
      if (this.phone == "") {
        this.$toast.fail('请输入手机号码');
        return;
      }
      if (this.phone.length != 11) {
        this.$toast.fail('请输入正确的手机号码');
        return;
      }
      if (this.code == "") {
        this.$toast.fail('请输入验证码');
        return;
      }
      if (this.checked == false) {
        this.$toast.fail('请确认已阅读协议');
        return;
      }
      this.showDialog = true;
    },
    exit(){
      this.showDialog = false;
    },
    kaitong(){
        // console.info(this.checked);
        
        var data = {
          phone: this.phone,
          code: this.code,
          packagesId: '756cb369f14844499b1e9c8b2c7f794f',
        };
        // console.info(data);
      this.$post("MarketingOwn/ValidySMSCode", data).then((res) => {
        // console.info(res);
        if (res.code == 0) {
          this.code = "";
          alert("您已成功订购慧推广权益升级版12期合约"); 
          this.showDialog = false;         
        } else {
          this.$toast.fail(res.resultMsg);
        }
      });
    },
    onClickLeft(){
        this.$router.go(-1);
    },
    getcode () {
      if (this.phone == "") {
        this.$toast.fail('请输入手机号码');
        return;
      }
      if (this.phone.length != 11) {
        this.$toast.fail('请输入正确的手机号码');
        return;
      }
      // if (this.code == "") {
      //   this.$toast.fail('请输入验证码');
      //   return;
      // }
      if (this.checked == false) {
        this.$toast.fail('请确认已阅读协议');
        return;
      }
      var data = {
        phone: this.phone,
        packagesId: '756cb369f14844499b1e9c8b2c7f794f',
      }
        // this.countDown()
        this.$post("MarketingOwn/SendSMSCode", data).then((res) => {
          console.info(res);
          if (res.code == "0") {
            this.$toast.success("验证码已发送至手机！");
            this.isSmsSend = true
            this.countDown()
          } else {
            this.$toast.fail(res.resultMsg);
          }
        });
      
    },
    countDown () {
      // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
      this.timer = setInterval(() => {
        // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
        this.sendBtnText = `(${this.counter}秒)后重新发送`
        this.counter--
        if (this.counter < 0) {
          // 当计时小于零时，取消该计时器
          this.isSmsSend = false
          this.sendBtnText = '获取验证码'
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
  mounted () {
    // this.token = this.$route.query.token;
    // if(this.token == null){
    //     this.token = sessionStorage.getItem("token");
    // }
    // if(this.token == null){
    //     console.info("无用户token");
    //     location.href =
    //     "http://token.xjjkzs.com/WeiXin/Login?Spm=wxdcd9a0bf1d353677&state=service&ReturnUrl=" +
    //     window.location.href;
    // }
    // this.$store.dispatch("setToken", this.token);    
    // sessionStorage.setItem("token",this.token);
  },
};
</script>


<style scoped>
.myBar{
    /* background-image: url("../assets/images/bar.png"); */
    background-color: #79c3ff;
    background-size: 100% 100%;
}
::v-deep .van-nav-bar__text {
  color: white;
}
.bg {
  width: 100%;
  /**宽高100%是为了图片铺满屏幕  height: 100%;*/
  /* height: 100%; */
  position: absolute;
}
.fuwuimg{
  position: relative;
}
.kaitong {
  /* width: 130px;
  height: 30px; */
  /* position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* width: 70%;
  height: 40px; */
  width: 100%;
  background-color: rgb(32,76,227);
  color: white;
  text-align: center;
  font-size: 18px;
  height: 5%;
}
.kaitongform{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 40%;
  z-index: 20;
}
.sendcode{
  background-color: rgb(32,76,227);
  color: white;
  text-align: center;
  font-size: 14px;
  width: 118%;
}
.shuoming{
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  text-align: center;
  font-size: 14px;
  z-index: 99;
}
.queren {
	  position: absolute;
    /* top: 40%;
	left: 50%; */
	/* transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%); */
    width:100%;
    height:100%;
    z-index: 2000;
    border-radius: 30px;
    background-image: url("../assets/images/erciqueren.png");
    background-size: 100% 100%;
}
.shadow {
	position: fixed;
	/* top: 0;
	bottom: 0;
	left: 0;
	right: 0; */
	background: #00000000;
	/* z-index: 1000; */
    /* display: none; */
  overflow: hidden;
  margin: 0 auto;
  width: 95%;
  height:450px;
  border-radius: 30px;
}

.btn-tuichu{
  position: absolute;
  width: 17%;
  height: 8%;
  top: 85%;
	left: 27%;
	transform: translate(-50%, -50%);
  background: #00000000;
  border: none;
}
.btn-dingou{
  position: absolute;
  width: 37%;
  height: 11%;
  top: 85%;
	left: 67%;
	transform: translate(-50%, -50%);
  background: #00000000;
  border: none;
}
</style>
