<template>
  <div class="bg">
    <!-- <img class="background"
         src="@/assets/images/peizhen_xinjiang.jpg">
    <van-button class='b_398'></van-button>
    <van-button class='b_198'></van-button> -->
    <div>
        <van-button class='record' @click="record">营销查询</van-button>
        <van-button class='record2' @click="record2">营销切换</van-button>
        <van-button class='record3' @click="record3">体检报告</van-button>
        <van-button class='record4' @click="record4">抵扣券</van-button>
        </div>
  <!-- <div>
    <van-radio-group v-model="radio">
  <van-radio name="1">单选框 1</van-radio>
  <van-radio name="2">单选框 2</van-radio>
</van-radio-group>
  </div> -->

    
  </div>
</template>

<script>
import axios from 'axios';
import wx from "weixin-js-sdk"
import CryptoJS from 'crypto-js'
export default {
  data () {

    return {
        token:'',
        linkUrl: '',
        radio:'1',
        accessToken: null,
        ticket:'',
        localId:'',
        message:'',
    };
  },
  methods: {
    record(){
        location.href =
        "http://localhost:8080/indexorder9"
        window.location.href;
    },
    record2(){
        location.href =
        "http://localhost:8080/available/availableLogin?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVSUQiOjIwMDAwMzA0MCwiQ2xpZW50VHlwZSI6IldlaVhpbiIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzM2NDE3NDk3fQ.NbOAYJU72dYxbHuAeyR_pkaBd2IkMsvMeC1u3r9J1iM"
        window.location.href;
    },
    record3(){
      location.href =
        // "http://localhost:8081/tjbg/tijian_xj&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVSUQiOjIwMDAwMzA0MCwiQ2xpZW50VHlwZSI6IldlaVhpbiIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzMxNDg3MDY3fQ.topMrVmBZgX1DXJzMsQpYF8MjHyppubpWgmHyi49Hi8"
        "http://localhost:8080/zxorderlist?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVSUQiOjIwMDAwMDAwMywiQ2xpZW50VHlwZSI6IldlaVhpbiIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzM1OTU0NzcyfQ.f9IPygGHCtLtqvjoDBxE6ONpoYI-QCvp9i77iRM9gD0"
        window.location.href;
    },
    record4(){
        location.href =
        "http://localhost:8080/dikou/dikou?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVSUQiOjIwMDAwMDAwMywiQ2xpZW50VHlwZSI6IldlaVhpbiIsInJvbGUiOiJ1c2VyIiwiZXhwIjoxNzM1OTU0NzcyfQ.f9IPygGHCtLtqvjoDBxE6ONpoYI-QCvp9i77iRM9gD0"
        window.location.href;
        
    },
    
    generateLink() {
      // 根据条件生成链接逻辑
      var p = { typeId: 1, eventId: 801 }
      this.linkUrl = 'cloudjs://msg?p=' + encodeURIComponent(JSON.stringify(p));
      console.info(this.linkUrl);
      return this.linkUrl;
    }
  },
  mounted () {
    // var p = { typeId: 1, eventId: 801 }
    // this.linkUrl = 'cloudjs://p=' + encodeURIComponent(JSON.stringify(p));
    // console.info(this.linkUrl);
    // const linkElement = this.$el.querySelector('a');
    // console.log(linkElement.getAttribute('href'));
  },
};
</script>

<style scoped>
.bg{
  display: flex
}
.background {
  width: 100%;
  /**宽高100%是为了图片铺满屏幕  height: 100%;*/
  /* height: 100%; */
  z-index: -1;
  position: absolute;
}
.record{
    width: 6.25rem;
    position: relative;
    /* left: 100px;
    top: 100px; */
}
.b_398 {
  position:fixed;
  width: 130px;
  height: 30px;
  border-radius:7px;
  margin-left: 55%;
  margin-top: 36%;
  
}
.b_198 {
  position: relative;
  width: 130px;
  height: 30px;
  left: 30%;
  top: 500px;
  
  /* margin-left: 54%;
  margin-top: 13.5%; */
  border-radius:7px;
  /* border: none;
  background: #00000000; */
}
</style>