<template>
  <div id="app">
    <router-view />

    <!-- <van-tabbar v-show="$store.state.tabbar"
                v-model="$store.state.tabbarActive"
                active-color="#4fc08d"
                safe-area-inset-bottom>
      <van-tabbar-item icon="description"
                       to="/home">今日推荐</van-tabbar-item>
     
      <van-tabbar-item icon="setting-o"
                       to="/setting">我的</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  // mounted () {
  //   this.active = this.$store.state.tabbarActive;
  // },
  // methods: {
  //   onChange () {
  //     this.$store.dispatch("setTabbar", this.active);
  //   },
  // },
};
</script>

<style>
html{
  height: 100%;
}
#app {
  min-height: 100vh;
  height: 100%;
}

body {
  height: 100%;
  background-color: #f1efef;
}
.van-nav-bar {
  background-color: #4fc08d;
}
.van-nav-bar__title {
  color: #fff;
}
.van-nav-bar__left {
  color: #fff;
}
.van-nav-bar__text {
  color: #fff;
}
.van-nav-bar .van-icon {
  color: #fff;
}
</style>
