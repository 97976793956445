import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import axios from 'axios'
import wx from "weixin-js-sdk"

import { get, post, imgpost, infoget ,wxget, wxpost, wxpost2,smspost,infopost} from './assets/js/Api'
// import "./assets/js/lib-flexible/flexiable.js"
// require('es6-promise').polyfill();
// require('es6-promise/auto')

Vue.prototype.$ajax = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$imgpost = imgpost;
Vue.prototype.$infoget = infoget;
Vue.prototype.$infopost = infopost;
Vue.prototype.$wxget = wxget;
Vue.prototype.$wxpost = wxpost;
Vue.prototype.$wxpost2 = wxpost2;
Vue.prototype.$smspost = smspost;
// Vue.prototype.$wx = wx;

Vue.config.ignoredElements = [
  'wx-open-launch-weapp'
]

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)
import {
  Form,
  Tabbar,
  TabbarItem,
  Icon,
  NavBar,
  Card, Toast, Dialog, Field, Cell, CellGroup, Popup,
  Image as VanImage,
  Col, Row,
  ActionSheet,
  Tab, Tabs,
  List,
  Rate,
  empty,
  Slider,
  Button,
  Checkbox, CheckboxGroup, Calendar,circle,
  NoticeBar,
  Step, Steps,Search ,
  GoodsAction, GoodsActionIcon, GoodsActionButton,
  RadioGroup, Radio,
  Progress, Picker,
  Grid, GridItem,
  Divider,
  Uploader,
  Tag,
  Lazyload,
  Swipe, SwipeItem,
  Loading,
  DropdownMenu, DropdownItem,
} from 'vant';
Vue.use(Form);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Card);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Rate);
Vue.use(empty);
Vue.use(Slider);
Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Step);
Vue.use(Steps);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Progress);
Vue.use(Grid).use(GridItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Divider);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Calendar);
Vue.use(Tag);
Vue.use(Lazyload);
Vue.use(Swipe).use(SwipeItem);
Vue.use(circle);
Vue.use(Loading);
Vue.use(Search );
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
// Vue.use(clipboard);
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.prototype.$dialog = Dialog;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
